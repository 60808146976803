import {Component} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'voxmate-invalid-account',
    templateUrl: './invalid-account-page.component.html',
    styleUrls: ['./invalid-account-page.component.less']
})
export class InvalidAccountPage {

    constructor(private readonly route: Router) {
    }

    async back() {
        await this.route.navigate(["/"]);
    }
}
