<div class="chip-list" #chips>
    <div class="chip" *ngFor="let component of components"
         mat-ripple
         [class.middle]="!component.last && !component.first"
         [class.last]="component.last"
         [class.first]="component.first" >
        {{component.value}}
        <fa-icon icon="arrow-right" *ngIf="!component.last"></fa-icon>
    </div>
</div>
