import {Component, Input, OnInit} from '@angular/core';
import {IVoxletConfiguration, IVoxletSettingsField} from "@common/models";
import {IRadioResourceLocator, IRadioSearchResult, IRadioStreams, IStation, IStationDescriptor, Radio} from "./radio";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "@services/global.service";
import {SessionService} from "@services/session.service";
import {removeArrayElement} from "@common/array";

const RadioFunction = "voxlet_radio"

class ResourceLocator implements IRadioResourceLocator {

    constructor(private readonly client: HttpClient, private readonly session: SessionService) {

    }

    async resolveStream(guideId: string): Promise<IRadioStreams> {
        return await this.session.fhttp(RadioFunction, "resolveStreams", {"guideId": guideId});
    }

    async search(query: string): Promise<IRadioSearchResult> {
        console.log("Search for", query)
        return await this.session.fhttp(RadioFunction, "search", {"query": query});
    }
}

function array_move<T>(arr: T[], old_index: number, new_index: number) {
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

@Component({
    selector: 'voxmate-radio-search',
    templateUrl: './radio-search.component.html',
    styleUrls: ['./radio-search.component.less']
})
export class RadioSearchComponent implements OnInit {

    @Input() field: IVoxletSettingsField;
    @Input() config: IVoxletConfiguration;

    private readonly radio: Radio;

    searchString: string = "";
    searched: boolean = false;

    searchResults: IStationDescriptor[] = [];
    stations: IStation[] = [];

    constructor(private readonly client: HttpClient, private readonly global: GlobalService, private readonly session: SessionService) {
        this.radio = new Radio(global, new ResourceLocator(client, session));
    }

    async search() {

        if (this.searchString.length === 0) {
            await this.global.announce("Please enter a search string to search for");
            return
        }

        this.searchResults = await this.global.freeze(this.radio.search(this.searchString), "Searching...");
        console.log("Search results", this.searchResults.length);
        if (this.searchResults.length > 0)
            await this.global.announce(`Found ${this.searchResults.length} stations`);
        else await this.global.announce("Couldn't find any stations matching your query");
        this.searched = true
    }

    async addStation(sd: IStationDescriptor) {
        const station = await this.global.freeze(this.radio.getStation(sd), "Trying to find a supported stream for this station");
        if (station)
            this.stations.push(station);
        else {
            await this.global.showWarningBox("Sorry, but we couldn't find a supported stream for this station", "Problem Adding Station");
        }

        //TODO: Notify that we couldn't add station
    }

    ngOnInit(): void {
        const stations: IStation[] = (this.config[this.field.target] as any);

        // Delete bad stations
        removeArrayElement(stations, null)
        removeArrayElement(stations, undefined)

        this.stations = stations;
    }

    async removeStation(station: IStation) {
        removeArrayElement(this.stations, station)
    }

    isStationAddable(station: IStationDescriptor) {
        if (!station) return false;

        for (let s of this.stations)
            if (s.id === station.id)
                return false;

        return true;
    }

    move(station: IStation, direction: number) {
        const idx = this.stations.indexOf(station);
        if (idx === -1)
            return;

        const n = this.stations.length;
        let next = idx + direction;

        while (next < 0)
            next = next + n;

        while (next >= n)
            next = next - n;

        array_move(this.stations, idx, next);
    }

    get group() {
        return this.config["group"] || false;
    }
}
