import {Component, Input, OnInit} from '@angular/core';
import {IVoxletConfiguration, IVoxletSettingsField} from "../../../../../../common/models";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../../../../../services/global.service";
import {FirebaseDocumentChangeTracker} from "../../../../../../common/changetracker";


interface IRedditAuthInit {
    auth_url: string;
    token: string;
}

interface IRedditAuthStatus {
    status: string;
    ok: boolean;
    error: string
}

const INTEGRATIONS = "https://integrations.voxmate.net/";

@Component({
    selector: 'voxmate-reddit-login',
    templateUrl: './reddit-login.component.html',
    styleUrls: ['./reddit-login.component.less']
})
export class RedditLoginComponent implements OnInit {

    @Input() field: IVoxletSettingsField;
    @Input() config: IVoxletConfiguration;

    private _loading: boolean = true;
    private _state = "not-inited";

    constructor(
        private readonly client: HttpClient,
        private readonly global: GlobalService,
    ) {
    }

    async ngOnInit() {
        const token = this.config[this.field.target];
        if (token) {

            const status = await this.client.post(`${INTEGRATIONS}reddit-status`, {
                "token": token
            }).toPromise() as IRedditAuthStatus;

            if (status.ok && status.status === "granted") {
                this._state = "granted";
            } else {
                this._state = "invalid";
            }
        }

        this._loading = false;
    }

    async initRedditLogin() {

        const resultTask = this.client.post(`${INTEGRATIONS}reddit-init`, {
            "sourceUrl": location.href
        }).toPromise();

        const result = await this.global.freeze(resultTask, "Getting ready...") as IRedditAuthInit;

        this.config[this.field.target] = result.token;
        const save = FirebaseDocumentChangeTracker.flush(this.config);
        await this.global.freeze(save, "Saving...");

        location.replace(result.auth_url);
    }

    get isSetupCorrectly() {
        return this._state === "granted";
    }

    get isSetupInvalid() {
        return this._state === "invalid";
    }

    get needsSetup() {
        return this._state === "not-inited";
    }

    async disconnectReddit() {
        this.config[this.field.target] = null;
        const save = FirebaseDocumentChangeTracker.flush(this.config);
        await this.global.freeze(save, "Saving...");
        await this.global.announce("Cleared your Reddit Credential. You can also revoke the credential from the Reddit Website.");
        this._state = "not-inited";
    }
}
