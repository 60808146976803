import {Component, OnInit} from '@angular/core';
import {IVoiceSettingsSpecification, IVoxmateSettings, SessionService} from "@services/session.service";
import {GlobalService} from "@services/global.service";
import {SUPPORTED_LANGUAGES} from "@app/constants";

@Component({
    selector: 'voxmate-voxmate',
    templateUrl: './voxmate-page.component.html',
    styleUrls: ['./voxmate-page.component.less']
})
export class VoxmatePage implements OnInit {

    settings: IVoxmateSettings;
    languages = SUPPORTED_LANGUAGES;

    constructor(
        private readonly session: SessionService,
        private readonly global: GlobalService
    ) {
    }

    async ngOnInit() {
        this.global.enableLoadingState();
        this.settings = await this.session.getSettings();
        this.global.disableLoadingState();
    }

    get haveSetupCustomVoices(): boolean {
        for (let lang of this.languages) {
            if (lang.code) {
                const lp = this.settings.textToSpeechSettings[lang.code] as IVoiceSettingsSpecification;
                if (lp) {
                    if (lp.info)
                        return true;

                    if (lp.content)
                        return true;

                    if (lp.dynamicContent)
                        return true;
                }
            }
        }

        return false;
    }

    async resetCustomVoiceSettings() {

        const ok = await this.global.showConfirmationBox(
            "This will reset all your voice settings." +
            " You will need to pick voices from your device, if you don't like the default ones.");

        if (ok) {
            for (let lang of this.languages) {
                if (lang.code) {
                    this.settings.textToSpeechSettings[lang.code] = {
                        "content": null,
                        "dynamicContent": null,
                        "info": null
                    };
                }
            }
        }
    }

    async nudgeDefaultSpeed(v: number) {

        const tts = this.settings.textToSpeechSettings;
        tts.defaultSpeed += v;

        if (tts.defaultSpeed > 4)
            tts.defaultSpeed = 4;

        if (tts.defaultSpeed < 1)
            tts.defaultSpeed = 1;

        await this.global.announce("Set voice speed to " + tts.defaultSpeed + "x", false)
    }

    get voiceSpeedAtMinimum() {
        return this.settings.textToSpeechSettings.defaultSpeed <= 1;
    }

    get voiceSpeedAtMaximum() {
        return this.settings.textToSpeechSettings.defaultSpeed >= 4;
    }
}
