<div class="fields">
    <div class="field">
        <label for="username">Username</label>
        <input id="username" [(ngModel)]="username" (change)="save()">
    </div>

    <div class="field">
        <label for="password"> Password </label>
        <input id="password" type="password" autocomplete="new-password" [(ngModel)]="password">
    </div>
</div>

<div class="actions">
    <button (click)="save()" mat-raised-button color="primary">Save</button>
    <button (click)="clear()" mat-button>Clear</button>
</div>

