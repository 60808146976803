import {ErrorHandler, Injectable} from "@angular/core";
import * as Sentry from "@sentry/browser";
import {Severity} from "@sentry/browser";
import {isBrowserUnsupported} from "@services/utility";

const isDevelopment = location.hostname === 'localhost' || location.hostname === '127.0.0.1';

@Injectable({
    providedIn: 'root'
})
export class SentryErrorHandler implements ErrorHandler {

    constructor() {
    }

    handleError(error) {

        if (isDevelopment)
            throw error;

        if (isBrowserUnsupported())
            throw error;

        Sentry.captureException(error.originalError || error);
    }

    captureError(text: string, error: any) {
        if (isBrowserUnsupported())
            return;

        Sentry.captureMessage(text);
        Sentry.captureException(error)
    }

    captureWarning(text: string) {
        if (isBrowserUnsupported())
            return;

        Sentry.captureMessage(text, Severity.Warning)
    }
}
