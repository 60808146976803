<div class="login-area">
    <p class="instruction">
        To obtain your login code
        <voxmate-path path="Open Voxmate->Settings->Log in to Portal"></voxmate-path>
    </p>

    <div class="login-window">
        <div class="error" *ngIf="haveError">
            {{error}}
        </div>

        <div class="login-form">

            <h1>Enter your {{codeLength}}-digit login code</h1>

            <div class="input-container">
                <input (keyup.enter)="signIn()"
                       aria-label="Code field" [(ngModel)]="code" (ngModelChange)="update()" [maxLength]="codeLength">
            </div>

            <div class="actions">
                <button type="submit" (keyup.enter)="signIn()"
                        mat-raised-button color="accent" [disabled]="invalid" (click)="signIn()">Login
                </button>
            </div>

            <div class="login-with-google">
                <p>Login with your Google Account.</p>
                <div class="login-with-google">
                    <button (click)="signInWithGoogle()" mat-raised-button color="accent">
                        Login with a Google Account
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
