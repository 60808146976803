import {NgZone} from "@angular/core";
import {AngularFirestoreDocument} from "@angular/fire/firestore";
import {Subscription} from "rxjs";
import firebase from "firebase";
import {removeArrayElement} from "@common/array";


function checkNeedsTranscription(source: any, destination: any, key: string): [boolean, boolean, boolean] {

    const sv = source[key];
    const dv = destination[key];

    if (sv !== null && dv !== null && dv !== undefined) {
        const match = sv.constructor === dv.constructor;
        if (!match)
            return [false, false, true];
    }

    if (sv === null) {
        return [true, true, sv !== dv];
    }

    if (dv === null) {
        return [true, true, sv !== dv];
    }

    if (typeof sv !== "object") {
        return [true, true, sv !== dv];
    }

    return [false, true, true];
}

function stableSortedObject(value: any) {
    if (value === null)
        return null;

    if (typeof value === "object") {
        if (Array.isArray(value))
            return value.map(stableSortedObject);

        return Object.keys(value).sort().reduce(
            (obj, key) => {
                obj[key] = stableSortedObject(value[key]);
                return obj;
            }, {});
    }

    return value;
}

function orderedJsonStringify(obj: any) {
    return JSON.stringify(stableSortedObject(obj));
}

function transcribe(source: any, destination: any): boolean {

    let changed = false;

    for (const key in destination)
        if (destination.hasOwnProperty(key))
            if (!source || !source.hasOwnProperty(key)) {
                delete destination[key];
                changed = true;
            }

    for (const key in source) {
        if (source.hasOwnProperty(key)) {
            if (destination.hasOwnProperty(key)) {
                const [simple, tmatch, needs] = checkNeedsTranscription(source, destination, key);

                if (needs) {
                    if ((tmatch && simple) || !tmatch) {
                        destination[key] = source[key];
                        changed = true;
                    } else {
                        const subc = transcribe(source[key], destination[key]);
                        changed = changed || subc;
                    }
                }
            } else {
                destination[key] = source[key];
                changed = true;
            }
        }
    }

    return changed;
}

interface Watcher<T> {
    soft: boolean;
    fn: (data: T) => void;
}

class State<T> {

    private _ready: Promise<T> = null;
    private _readyResolver: (status: T) => void;

    constructor() {
        this.reset();
    }

    signal(status: T) {
        this._readyResolver(status);
    }

    get ready(): Promise<T> {
        return this._ready;
    }

    reset() {
        this._ready = new Promise((resolve) => {
            this._readyResolver = resolve;
        });
    }
}

class AsyncFlag {

    private readonly promise: Promise<void>;
    private marker: () => void;

    constructor() {
        this.promise = new Promise<void>(resolve => {
            this.marker = resolve;
        });
    }

    markReady() {
        const marker = this.marker;
        if (marker) marker();
        else {
            const handle = setInterval(() => {
                const marker = this.marker;
                if (marker) {
                    marker();
                    clearInterval(handle);
                }
            }, 1);
        }

    }

    async ready() {
        await this.promise;
    }
}

const globalChangeTrackerRegistry: FirebaseDocumentChangeTracker<any>[] = [];

export class FirebaseDocumentChangeTracker<T> {

    private _initialized: boolean = false;
    private _closed: boolean = false;

    private _updating: boolean = false;
    private _updateFlag: AsyncFlag | null = null;
    private _reTrigger: boolean = false;

    private _data: T;
    private _copy: string;
    private _state: State<boolean>;
    private _echos: State<boolean>[] = [];
    private _watchers: { [id: string]: Watcher<T> } = {};
    private _debounceTimeout: any;
    private _suspended: boolean = false;
    private _watcherId = 0;
    private _updateSubscription: Subscription | null = null;
    private _missing = false;
    private _reference: firebase.firestore.DocumentReference<T> = null;

    constructor(afReference: AngularFirestoreDocument<T>,
                private readonly zone: NgZone) {

        // Use native firebase, angular fire has bugs.
        this._reference = firebase.firestore().doc(afReference.ref.path) as any
        this._reference.onSnapshot(snapshot => {
            zone.run(() => this.onSnapshot(snapshot.data()));
        });

        this._state = new State<boolean>();
        globalChangeTrackerRegistry.push(this);
    }

    private onSnapshot(entity: T | undefined) {

        if (entity === undefined) {
            this._initialized = true;
            this._data = undefined;
            this._missing = true;
            this._state.signal(false);
            return
        }

        const copy = orderedJsonStringify(entity)

        if (this._closed)
            return

        let reinit = false;

        if (!this._updating) {
            console.log("Data update has been received");
            this.transcribe(entity);
        } else {

            // An update came in while a save operation was in progress
            // This could (and should) have been a save-echo, that doesn't make a change.

            const dataCopy = JSON.parse(JSON.stringify(this._data));
            if (transcribe(entity, dataCopy)) {
                // Okay, so this could happen if there was a significant network delay, or
                // If someone else was editing our data at the same time.

                // We should try bringing the data into consistent state
                console.warn("An echo carrying different information than expected has been observed. Slow network?");
                reinit = true;
            }
        }

        this._copy = copy;

        if (this._echos.length > 0) {
            const echo = this._echos.shift();
            echo.signal(!reinit);
        }

        this._state.signal(true);
    }

    private transcribe(entity: T): boolean {

        let changed = false;

        if (!this._initialized) {
            this._data = entity;
            this._initialized = true;
            changed = true;
        } else if (this._data !== null) {
            if (transcribe(entity, this._data)) {
                changed = true;
            }
        } else if (entity !== null) {
            this._data = entity;
            changed = true;
        }

        this.runWatchers(changed);

        return changed;
    }

    private async update() {

        if (this._closed)
            return;

        if (this._updating) {
            this._reTrigger = true;
            return;
        }

        this._updating = true;

        if (!this._updateFlag)
            this._updateFlag = new AsyncFlag();

        console.log("Saving changes");
        if (this._suspended) {
            console.warn("Changes have not been saved because change tracker is suspended");
        } else {

            const echo = new State<boolean>();
            this._echos.push(echo);

            const fcopy = orderedJsonStringify(this._data);
            const entity = JSON.parse(fcopy);

            console.log("Updating...")
            await this._reference.update(entity)

            console.log("Save confirmed");
            this._copy = fcopy;

            const gotecho = await Promise.race([echo.ready,
                new Promise(r => setTimeout(() => r(true), 10000))]);

            if (this._closed) {
                this._updateFlag?.markReady();
                this._updateFlag = null;
                return;
            }

            removeArrayElement(this._echos, echo)

            if (!gotecho) {
                console.warn("Changes have been saved, but a destructive echo has been observed. Will try saving again.");
                this._reTrigger = true;
            } else {
                console.log("Changes have been saved and echoed");
            }
        }

        this._updating = false;

        if (this._reTrigger) {
            this._reTrigger = false;
            this.triggerDebounced();
        } else {
            const flag = this._updateFlag;
            if (flag) {
                flag.markReady();
            }
            this._updateFlag = null;
        }
    }

    private runWatchers(changed: boolean) {

        if (this._closed)
            return;

        for (const watcherId in this._watchers) {
            if (this._watchers.hasOwnProperty(watcherId)) {
                const watcher = this._watchers[watcherId];
                if (changed || watcher.soft) {
                    watcher.fn(this.data);
                }

            }
        }
    }

    private async trigger() {
        if (!this._initialized)
            return;

        if (this._closed)
            return;

        if (!this._updating) {
            await this.flush();
        } else {
            this._reTrigger = true;
        }
    }

    // API

    async triggerDebounced() {
        this.zone.runOutsideAngular(() => {
            clearTimeout(this._debounceTimeout);
            this._debounceTimeout = setTimeout(async () => {
                if (this._closed)
                    return;

                if (!this._suspended) await this.trigger();
                else await this.triggerDebounced();
            }, 250);
        });
    }

    async flush() {
        if (this._copy !== orderedJsonStringify(this._data)) {
            await this.update();
        } else {
            this._updateFlag?.markReady();
            this._updateFlag = null;
        }
    }

    async operationsEnd() {
        const flag = this._updateFlag;
        if (!flag) {
            return;
        }
        await flag.ready();
    }

    enableSuspendedAnimation(flag: boolean = true) {
        this._suspended = flag;
    }

    watch(fn: (data: T) => void, soft: boolean = false): () => void {

        if (this._closed)
            return () => {

            };

        if (this._initialized)
            fn(this.data);

        const watcherId = (this._watcherId++).toString();
        this._watchers[watcherId] = {fn: fn, soft: soft};

        return () => {
            delete this._watchers[watcherId];
        };
    }

    get data(): T {
        return this._data as T;
    }

    get ready(): Promise<boolean> {
        return this._state.ready;
    }

    close() {
        this._updateFlag = null;
        this._closed = true;
        this._updateSubscription?.unsubscribe();
        this._watchers = {};

        removeArrayElement(globalChangeTrackerRegistry, this)
    }

    static async flush(object: any) {

        for (let tracker of globalChangeTrackerRegistry) {
            if (tracker.data === object) {
                await tracker.flush();
                return
            }
        }

        throw "Invalid object";
    }
}
