import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

interface IDialogData {
    title: string;
    text: string;
    level: "warning"
}


@Component({
    selector: 'voxmate-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.less']
})
export class ConfirmationComponent {

    constructor(public dialogRef: MatDialogRef<ConfirmationComponent>,
                @Inject(MAT_DIALOG_DATA) public data: IDialogData) {
    }

    close(value: boolean) {
        this.dialogRef.close(value);
    }
}
