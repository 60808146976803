import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import {environment} from "../environments/environment";

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";

import {AngularFireModule} from "@angular/fire";
import {AngularFirestoreModule} from "@angular/fire/firestore";

import {RouterModule, Routes} from "@angular/router";
import {AngularFireAuthModule} from "@angular/fire/auth";

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fab} from "@fortawesome/free-brands-svg-icons"

import {HomePage} from './pages/home/home-page.component';
import {VoxletsPage} from './pages/voxlets/voxlets-page.component';
import {VoxmatePage} from './pages/voxmate/voxmate-page.component';
import {ServicesPage} from './pages/services/services-page';
import {InvalidAccountPage} from './pages/invalid-account/invalid-account-page.component';

import {VoxletEditorComponent} from './pages/voxlets/components/voxlet-editor/voxlet-editor.component';
import {VoxletEditorFieldComponent} from './pages/voxlets/components/voxlet-editor/voxlet-editor-field/voxlet-editor-field.component';
import {RedditLoginComponent} from './pages/voxlets/components/voxlet-editor/voxlet-editor-field/reddit-login/reddit-login.component';
import {RadioSearchComponent} from './pages/voxlets/components/voxlet-editor/voxlet-editor-field/radio-search/radio-search.component';

import {LoginComponent} from './login/login.component';
import {PathWidget} from './widgets/path/path-widget.component';

import {
    AngularFireAuthGuard,
    AngularFireAuthGuardModule,
    redirectLoggedInTo,
    redirectUnauthorizedTo
} from "@angular/fire/auth-guard";
import {MaterialModule} from "./material.module";
import {SmartFocusDirective} from './directive/smart-focus.directive';
import {SessionService} from "@services/session.service";
import {SentryErrorHandler} from "@services/sentry.service";
import {NotificationComponent} from './popups/notification/notification.component';
import {CredentialsComponent} from './pages/voxlets/components/voxlet-editor/voxlet-editor-field/credentials/credentials.component';
import {ConfirmationComponent} from './popups/confirmation/confirmation.component';


// This function must be NAMED, or it freaks out the CLI compiler
export function redirectUnauthorizedToLogin() {
    return redirectUnauthorizedTo(['login']);
}

// This function must be NAMED, or it freaks out the CLI compiler
export function redirectAuthorizedHome() {
    return redirectLoggedInTo([''])
}

function authorize() {
    return {
        canActivate: [AngularFireAuthGuard, SessionService],
        data: {authGuardPipe: redirectUnauthorizedToLogin}
    }
}

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: redirectAuthorizedHome}
    },
    {path: 'apps/:ident', component: VoxletsPage, ...authorize()},
    {path: 'apps', component: VoxletsPage, ...authorize()},
    {path: 'voxmate', component: VoxmatePage, ...authorize()},
    {path: 'services', component: ServicesPage, ...authorize()},
    {path: 'invalid-account', component: InvalidAccountPage},
    {path: '**', component: HomePage, ...authorize()}
];

@NgModule({
    declarations: [
        AppComponent,
        VoxletsPage,
        HomePage,
        VoxmatePage,
        VoxletEditorComponent,
        VoxletEditorFieldComponent,
        RedditLoginComponent,
        LoginComponent,
        PathWidget,
        SmartFocusDirective,
        InvalidAccountPage,
        RadioSearchComponent,
        NotificationComponent,
        CredentialsComponent,
        ConfirmationComponent,
        ServicesPage
    ],
    imports: [
        AngularFireAuthGuardModule,
        RouterModule.forRoot(routes, {enableTracing: false, scrollPositionRestoration: "enabled"}),
        BrowserModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        HttpClientModule,
        MaterialModule,
        FormsModule,
    ],
    providers: [{provide: ErrorHandler, useClass: SentryErrorHandler}],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas, far, fab);
    }
}
