import {EventEmitter, Injectable, NgZone} from '@angular/core';
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {SentryErrorHandler} from "@services/sentry.service";
import {MatDialog} from "@angular/material/dialog";
import {NotificationComponent} from "@app/app/popups/notification/notification.component";
import {isBrowserUnsupported} from "@services/utility";
import {ConfirmationComponent} from "@app/app/popups/confirmation/confirmation.component";
import {environment} from "@app/environments/environment";
import {removeArrayElement} from "@common/array";

interface IFrostedMessage {
    text: string;
}

const WIDTH_THRESHOLD = 820;

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    private _frostedMessages: IFrostedMessage[] = [];
    private _loadingDepth = 0;
    private _announcerClear = null;
    private _flipDevProd = false;

    public readonly isMobile = typeof window.orientation !== 'undefined';

    changes = new EventEmitter<void>();

    constructor(private readonly announcer: LiveAnnouncer,
                private readonly dialog: MatDialog,
                private readonly sentry: SentryErrorHandler,
                private readonly zone: NgZone) {
    }

    get large() {
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        return !this.isMobile && width > WIDTH_THRESHOLD;
    }

    get compact() {
        return !this.large;
    }

    async announce(text: string, polite: boolean = true) {

        if (this._announcerClear) {
            clearTimeout(this._announcerClear);
            this._announcerClear = null
        }

        const level = polite ? "polite" : "assertive";
        await this.announcer.announce(text, level);

        this._announcerClear = setTimeout(() => {
            this.announcer.clear()
        }, 10000);
    }

    enableLoadingState() {
        this.zone.run(() => {
            this._loadingDepth++;
            this.changes.emit();
        });
    }

    disableLoadingState() {
        this.zone.run(() => {
            this._loadingDepth--;
            this.changes.emit();
        });
    }

    async freeze<T>(task: Promise<T>, text: string = "Loading, please wait"): Promise<T> {

        await this.announce(text, false);

        const msg: IFrostedMessage = {
            text: text
        };

        this._frostedMessages.push(msg);
        const result = await task;
        removeArrayElement(this._frostedMessages, msg)

        this.announcer.clear();

        return result;
    }

    async showWarningBox(text: string, title: string = "Warning") {

        await this.announce(text);

        const dialogRef = this.dialog.open(NotificationComponent, {
            width: '600px',
            data: {title: title, text: text, level: "warning"}
        });

        return await dialogRef.afterClosed().toPromise();
    }

    async showConfirmationBox(text: string, title: string = "Are you sure?"): Promise<boolean | undefined> {

        await this.announce(text);

        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '600px',
            data: {title: title, text: text, level: "warning"}
        });

        return await dialogRef.afterClosed().toPromise();
    }

    get isLoading(): boolean {
        return this._loadingDepth > 0;
    }

    get isFrosted(): boolean {
        return this._frostedMessages.length > 0;
    }

    get isDev() {
        return !this.isProd;
    }

    get isProd() {
        const prod = environment.production;
        if (this._flipDevProd)
            return !prod;
        return prod;
    }

    get browserUnsupported() {
        return isBrowserUnsupported()
    }

    get frostedMessage(): string {
        return this._frostedMessages[this._frostedMessages.length - 1].text;
    }

    reportError(message: string, error: any) {
        this.sentry.captureError(message, error)
    }

    toggleDevOverride() {
        this._flipDevProd = !this._flipDevProd;
    }
}
