import {Component, Input, OnInit} from '@angular/core';
import {IVoxletConfiguration, IVoxletSettingsField} from "@common/models";

@Component({
    selector: 'voxmate-voxlet-editor-field',
    templateUrl: './voxlet-editor-field.component.html',
    styleUrls: ['./voxlet-editor-field.component.less']
})
export class VoxletEditorFieldComponent implements OnInit {

    @Input() config: IVoxletConfiguration;
    @Input() field: IVoxletSettingsField;

    constructor() {

    }

    ngOnInit() {

    }
}
