import {Component} from '@angular/core';
import {SessionService} from "@services/session.service";
import {GlobalService} from "@services/global.service";

@Component({
    selector: 'voxmate-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less']
})
export class LoginComponent {

    haveError = false;
    error = "";

    editable = true;
    codeLength = 6; //TODO: Maybe this should be in some setting somewhere?
    code: string = "";

    constructor(
        private readonly global: GlobalService,
        private readonly session: SessionService
    ) {
    }

    update() {
        this.code = this.code.toLocaleUpperCase();
    }

    get invalid() {
        return this.code.length != this.codeLength;
    }

    async signIn() {
        this.haveError = false;
        this.error = "";
        this.editable = false;
        const result = await this.global.freeze(this.session.signInWithToken(this.code), "Logging in, please wait");
        if (!result.signedIn) {
            this.haveError = true;
            this.error = result.signInError;
            await this.global.announce("There was an error signing you in: " + this.error, false);
        } else {
            await this.global.announce("Signed in to my.voxmate.com");
        }
    }

    async signInWithGoogle() {
        await this.session.signInWithGoogle();
    }
}
