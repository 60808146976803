export const environment = {
    production: false,
    hmr: true,
    voxletRepository: "system-debug",
    host: "https://apis.voxmate.com/v1/portal",
    firebase: {
        apiKey: 'AIzaSyBlIXR4-prJfK8G2dWlTCoT8hrLLrEn0M0',
        authDomain: 'voxmate-app.firebaseapp.com',
        databaseURL: 'https://voxmate-app.firebaseio.com',
        projectId: 'voxmate-app',
        storageBucket: 'voxmate-app.appspot.com',
        messagingSenderId: '367028765155'
    }
};
