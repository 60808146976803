<div class="split-view">

    <div class="sidebar" *ngIf="!global.compact">
        <h1 class="title">
            Voxmate Apps
        </h1>
        <div class="loading" *ngIf="loading" aria-label="Loading, please wait" tabindex="0">
            <mat-spinner [diameter]="40"></mat-spinner>
        </div>
        <ul *ngIf="voxlets">
            <li *ngFor="let voxlet of voxlets">
                <button [attr.aria-label]="'Configure ' + voxlet.name"
                        [class.active]="voxlet === currentVoxlet"
                        mat-button
                        class="voxlet"
                        (click)="open(voxlet)">

                    <mat-icon *ngIf="!voxletNeedsFontAwesomeIcon(voxlet)">{{getVoxletIcon(voxlet)}}</mat-icon>

                    <span *ngIf="voxletNeedsFontAwesomeIcon(voxlet)">

                        <mat-icon *ngIf="!voxletNeedsBrandIcon(voxlet)" fontSet="fa"
                                  fontIcon="{{getVoxletIcon(voxlet)}}"></mat-icon>

                        <mat-icon *ngIf="voxletNeedsBrandIcon(voxlet)" fontSet="fab"
                                  fontIcon="{{getVoxletBrandIcon(voxlet)}}"></mat-icon>
                        </span>
                    {{voxlet.name}}
                </button>
            </li>
        </ul>
    </div>

    <div class="main-view">
        <div class="hint" *ngIf="!currentVoxlet && !global.isLoading">
            <div class="content">
                <mat-icon class="icon" fontSet="fa" fontIcon="fa-question"></mat-icon>
                <p>Pick an app to configure its settings</p>
            </div>
        </div>

        <div class="voxlet-picker" *ngIf="global.compact && voxlets">
            <label aria-label="Change voxlet setting">
                <select [(ngModel)]="currentVoxletDescriptorModel" (change)="open(currentVoxletDescriptorModel)">
                    <option *ngFor="let voxlet of voxlets" [ngValue]="voxlet">{{voxlet.name}}</option>
                </select>
            </label>
        </div>

        <div class="voxlet-view" *ngIf="currentVoxlet">
            <voxmate-voxlet-editor [voxlet]="currentVoxlet"></voxmate-voxlet-editor>
        </div>
    </div>
</div>
