import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

interface IPathFragment {
    value: string;
    first: boolean;
    last: boolean;
}

@Component({
    selector: 'voxmate-path',
    templateUrl: './path-widget.component.html',
    styleUrls: ['./path-widget.component.less']
})
export class PathWidget implements OnInit {

    @Input('path') path: string = '';
    @ViewChild('chips') chips: ElementRef = null;

    components: IPathFragment[] = [];

    ngOnInit() {
        const parts = this.path.split('->');
        for (let i = 0; i < parts.length; ++i) {
            const part = parts[i];
            this.components.push({
                value: `${i + 1}. ${part.trim()}`,
                first: i === 0,
                last: i === parts.length - 1
            });
        }
    }
}
