<h1 smartfocus>{{greeting}}</h1>
<div class="options">
    <mat-card class="option" tabindex="0">

        <mat-card-header>
            <div mat-card-avatar>
                <mat-icon class="card-icon">settings</mat-icon>
            </div>
            <mat-card-title>
                <h2>General Settings</h2>
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <p>
                Change Voxmate general settings, such as text-to-speech engine options, haptics, announcements, and
                more.
            </p>
        </mat-card-content>

        <mat-card-actions class="actions">
            <button mat-raised-button color="primary" tabindex="0" (click)="configureVoxmate()">
                Manage Settings
            </button>
        </mat-card-actions>

    </mat-card>

    <mat-card class="option" tabindex="0">
        <mat-card-header>
            <div mat-card-avatar>
                <mat-icon class="card-icon">dashboard</mat-icon>
            </div>
            <mat-card-title>
                <h2>Voxmate Apps</h2>
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <p>
                Some Voxmate Apps have preferences that can be set through its settings, or
                through this portal.
            </p>
        </mat-card-content>

        <mat-card-actions class="actions">
            <button mat-raised-button color="primary" tabindex="0" (click)="configureVoxlets()">
                Configure Apps
            </button>
        </mat-card-actions>

    </mat-card>

    <mat-card class="option" tabindex="0">

        <mat-card-header>
            <div mat-card-avatar>
                <mat-icon class="card-icon">extension</mat-icon>
            </div>
            <mat-card-title>
                <h2>Plugins</h2>
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <p>
                Voxmate Plugins are third-party extensions to Voxmate that can enable functionality and deep integration
                with certain Voxmate Apps. Voxmate Plugins may need to be download, but Voxmate will handle that
                download for you.
            </p>
        </mat-card-content>

        <mat-card-actions class="actions">
            <button mat-raised-button color="primary" tabindex="0" (click)="configureVoxmateServices()">
                Manage Plugins
            </button>
        </mat-card-actions>

    </mat-card>
</div>
