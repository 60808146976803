<h2 class="name">
    {{field.name}}
</h2>

<div class="control">

    <div class="switch" *ngIf="field.type === 'switch'">
        <mat-checkbox [(ngModel)]="config[field.target]" aria-label="Enable {{field.name}} - {{field.description}}">
            Enable
        </mat-checkbox>
    </div>

    <div class="switch" *ngIf="field.type === 'textarea'">
            <textarea class="textarea" [(ngModel)]="config[field.target]"
                      aria-label="{{field.name}} - {{field.description}}"></textarea>
    </div>

    <voxmate-reddit-login
            *ngIf="field.type === 'reddit-login'"
            [config]="config"
            [field]="field">
    </voxmate-reddit-login>

    <voxmate-radio-search
            *ngIf="field.type === 'radio-search'"
            [config]="config"
            [field]="field">
    </voxmate-radio-search>

    <voxmate-credentials
            *ngIf="field.type === 'credentials'"
            [config]="config"
            [field]="field">
    </voxmate-credentials>

</div>

<div class="description" *ngIf="field.description && field.description.length > 0">
    {{field.description}}
</div>
