import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from "@sentry/browser";

if (environment.production) {

    Sentry.init({
        dsn: "https://5bfb85505e26460e99e917789b0690ef@o235894.ingest.sentry.io/5909382",
    });

    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
