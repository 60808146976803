import {GlobalService} from "@services/global.service";

export interface IRadioSearchResult {
    Items: {
        Index: number;
        Type: "Container" | "Station";
        ContainerType: "NotPlayableStations" | "Topics" | "Stations" | "Programs";
        AccessibilityTitle: string;
        GuideId: string;
        Description: string;
        Title: string;
        Children: {
            GuideId: string;
            Index: number;
            Type: "Station" | string;
            ContainerType: "Undefined";
            Image: string;
            AccessibilityTitle: string;
            Title: string;
            Subtitle: string;
            Description: string;
            Actions: {
                Follow: {
                    CanFollow: boolean;
                    IsFollowing: boolean;
                    FollowerCount: number;
                    GuideId: string;
                    FollowText: string;
                };
                Play: {
                    CanPlay: boolean;
                    IsLive: boolean;
                    GuideId: string;
                    SubscriptionRequired: boolean;
                    CanCast: boolean;
                    ScrollableNowPlaying: boolean;
                    StartTime?: Date;
                    PublishTime?: Date;
                    Duration?: number;
                };
                Echo: {
                    CanEcho: boolean;
                    EchoCount: number;
                    TargetItemId: string;
                    Scope: string;
                    Url: string;
                    SourceItemId: string;
                };
                Share: {
                    CanShare: boolean;
                    ShareUrl: string;
                    ShareText: string;
                    CanShareOnFacebook: boolean;
                    CanShareOnTwitter: boolean;
                };
                Profile: {
                    CanViewProfile: boolean;
                    Url: string;
                };
                Embed: {
                    CanEmbed: boolean;
                    CanEmbedTwitterAudioCard: boolean;
                    CanEmbedTwitterPlayerCard: boolean;
                };
            };
            IsTitleVisible: boolean;
            IsSubtitleVisible: boolean;
            Behaviors: {
                Default: {
                    ActionName: string;
                };
            };
            Context: {
                Token: string;
            };
        }[];
        IsTitleVisible: boolean;
        IsSubtitleVisible: boolean;
        Pivots: {
            More: {
                DisplayName: string;
                Count: number;
                Url: string;
                RequiresAuth: boolean;
            };
        };
    }[];
}

export interface IRadioStreams {
    compatible: boolean;
    url: string;
}

export interface IRadioResourceLocator {
    search(text: string): Promise<IRadioSearchResult>

    resolveStream(guideId: string): Promise<IRadioStreams>
}

export interface IStationDescriptor {
    id: string;
    name: string;
    description: string;
}

export interface IStation extends IStationDescriptor {
    group: string;
    url: string;
}

export class Radio {

    constructor(private readonly global: GlobalService, private readonly rc: IRadioResourceLocator) {

    }

    async search(text: string): Promise<IStationDescriptor[]> {
        const stations: IStationDescriptor[] = [];
        try {
            const result = await this.rc.search(text);
            for (let item of result.Items) {
                if (item.ContainerType == "Stations") {
                    for (let child of item.Children) {
                        stations.push({
                            id: child.GuideId,
                            name: child.Title,
                            description: child.Description,
                        });
                    }
                }

                if (item.Type == "Station") {
                    stations.push({
                        id: item.GuideId,
                        name: item.Title,
                        description: item.Description,
                    });
                }
            }
        } catch (e) {
            this.global.reportError("Error getting search results for: " + text, e)
        }
        return stations
    }

    async getStation(descriptor: IStationDescriptor): Promise<IStation | null> {

        try {
            const data = await this.rc.resolveStream(descriptor.id);
            if (!data.compatible)
                return null

            return {...descriptor, group: "", url: data.url}

        } catch (e) {
            this.global.reportError("Error Looking up Station " + descriptor.id, e)
            return null
        }
    }
}
