export class Deferred<T> {

    private resolver: (T) => void;
    private rejector: (reason?: string) => void;
    private readonly internalPromise: Promise<T>;

    constructor() {
        this.internalPromise = new Promise((res, rej) => {
            this.resolver = res;
            this.rejector = rej
        })
    }

    resolve(value: T) {
        this.resolver(value);
    }

    reject(reason?: string) {
        this.rejector(reason)
    }

    async promise(): Promise<T> {
        return await this.internalPromise
    }
}
