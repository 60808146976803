import {Component, DoCheck, EventEmitter, Input, NgZone, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {IVoxletConfiguration, IVoxletManifest} from "@common/models";
import {SessionService} from "@services/session.service";
import {GlobalService} from "@services/global.service";
import {Subscription} from "rxjs";
import {FirebaseDocumentChangeTracker} from "@common/changetracker";

@Component({
    selector: 'voxmate-voxlet-editor',
    templateUrl: './voxlet-editor.component.html',
    styleUrls: ['./voxlet-editor.component.less']
})
export class VoxletEditorComponent implements OnChanges, OnDestroy, DoCheck {

    loading = false;
    haveSettings = false;
    haveFields = false;

    @Input() voxlet: IVoxletManifest;
    private updates = new EventEmitter<void>();
    private _updateSubscription: Subscription | null = null;
    private _changeTracker: FirebaseDocumentChangeTracker<IVoxletConfiguration> = null;

    constructor(
        private readonly zone: NgZone,
        private readonly session: SessionService,
        private readonly global: GlobalService
    ) {
        this._updateSubscription = this.updates.subscribe(async () => {
            await this.update();
        })
    }

    async update() {
        this.loading = true;
        this.global.enableLoadingState();

        if (this._changeTracker)
            this._changeTracker.close();

        const defaultConfig = this.createDefaultVoxletConfiguration();
        const ref = await this.session.getVoxletConfigurationReferenceFor(this.voxlet.ident);

        let snapshot = await ref.ref.get();
        if (!snapshot.exists) {
            await ref.set(defaultConfig);
            snapshot = await ref.ref.get();
        }

        const data = snapshot.data();
        if (this.upgradeDocument(data))
            await ref.set(data);

        const tracker = new FirebaseDocumentChangeTracker(ref, this.zone);
        this._changeTracker = tracker;
        await tracker.ready;

        this.global.disableLoadingState();
        this.loading = false;
    }

    ngDoCheck() {
        if (this._changeTracker) {
            this._changeTracker.triggerDebounced();
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        this.haveSettings = false;
        this.haveFields = false;

        if (this.loading) {
            this.loading = false;
            this.global.disableLoadingState();
        }

        if (this._changeTracker) {
            this._changeTracker.close();
            this._changeTracker = null;
        }

        if (this.voxlet.settings) {
            if (this.voxlet.settings.fields && this.voxlet.settings.fields.length > 0)
                this.haveFields = true;
            this.haveSettings = this.haveFields
        }

        if (!this.haveSettings) {
            return;
        }

        this.updates.emit();
    }

    private upgradeDocument(data: IVoxletConfiguration): boolean {
        const defaultConfig = this.createDefaultVoxletConfiguration();
        let upgrade = false;

        // Check if new config option was added (EXTEND)
        for (let key in defaultConfig) {
            if (!data.hasOwnProperty(key)) {
                data[key] = defaultConfig[key];
                upgrade = true;
            }
        }

        // Check if an option has been removed (TRIM)
        for (let key in data) {
            if (!defaultConfig.hasOwnProperty(key)) {
                delete data[key];
                upgrade = true;
            }
        }

        return upgrade;
    }

    private createDefaultVoxletConfiguration(): IVoxletConfiguration {

        const config: IVoxletConfiguration = {};
        if (!this.voxlet.settings)
            return config;

        if (this.voxlet.settings.fields)
            for (let field of this.voxlet.settings.fields) {
                if (field.default !== undefined)
                    config[field.target] = field.default;
            }

        return config;
    }

    get configuration(): IVoxletConfiguration {
        if (this._changeTracker)
            return this._changeTracker.data;
        return null;
    }

    ngOnDestroy(): void {
        this._updateSubscription?.unsubscribe();
        if (this._changeTracker)
            this._changeTracker.close();
        this._changeTracker = null;
    }
}
