import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {SessionService} from "@services/session.service";

@Component({
    selector: 'voxmate-home',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.less']
})
export class HomePage {

    constructor(private readonly router: Router, readonly session: SessionService) {
    }

    get greeting(): string {
        const user = this.session.googleAccount;
        if (user) {
            return "Welcome to the Voxmate Portal, " + user.nick
        }
        return "Voxmate Configuration Portal"
    }

    async configureVoxmate() {
        await this.router.navigate(["voxmate"])
    }

    async configureVoxlets() {
        await this.router.navigate(["apps"])
    }

    async configureProfile() {
        await this.router.navigate(["profile"])
    }

    async configureVoxmateServices() {
        await this.router.navigate(["services"])
    }

}
