<div class="document-view">
    <h1>
        Get Voxmate on Google Play
    </h1>
    <div class="field">
        <h2 class="name">You don't seem have a Voxmate Account Yet...</h2>
        <div class="control">
            <p>
                In order to change your Voxmate settings, you'll first need to get the free Voxmate app from Google Play
                and sign in to the app with your Google account, then you can sign back in.
            </p>
            <div class="actions">
                <button mat-raised-button (click)="back()" color="accent">Back</button>
            </div>
        </div>
    </div>
</div>
