import {ChangeDetectorRef, Component, DoCheck, HostListener} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {SessionService} from "@services/session.service";
import {GlobalService} from "@services/global.service";

@Component({
    selector: 'app-root',
    templateUrl: "./app.component.html",
    styles: []
})
export class AppComponent implements DoCheck {

    constructor(private readonly router: Router,
                public readonly global: GlobalService,
                private readonly changeDetectorRef: ChangeDetectorRef,
                readonly session: SessionService) {

        global.changes.subscribe(() => {
            changeDetectorRef.detectChanges();
        });

        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                global.disableLoadingState()
            }
        })

    }

    async goHome() {
        await this.router.navigate([""])
    }

    get hasBackButton() {
        return this.router.url != "/" && this.router.url != "/login";
    }

    get isLoading() {
        return this.global.isLoading;
    }

    get signedIn() {
        return this.session.isSignedIn
    }

    ngDoCheck() {
        this.session.trigger()
    }

    async signOut() {
        await this.session.signOut()
    }

    @HostListener('window:resize')
    public detectResize(): void {
        // This doesn't need to do anything, just causes change detection to run on resize.
    }
}
