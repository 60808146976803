<h2 smartfocus>{{voxlet.name}}</h2>
<div class="voxlet-description">
    {{voxlet.description}}
</div>

<div class="loading" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>

<div *ngIf="!loading">

    <div class="no-settings" *ngIf="!haveSettings">
        <div class="icon">
            <mat-icon fontSet="fa" fontIcon="fa-umbrella-beach"></mat-icon>
        </div>
        <div class="message">
            There are no settings to configure for this voxlet at the moment.
        </div>
    </div>

    <div *ngIf="haveSettings && configuration">
        <div class="fields" *ngIf="haveFields">
            <voxmate-voxlet-editor-field *ngFor="let field of voxlet.settings.fields"
                                         [config]="configuration"
                                         [field]="field">
            </voxmate-voxlet-editor-field>
        </div>
    </div>

</div>
