import {Injectable, NgZone} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CanActivate, Router} from "@angular/router";

import {Observable} from "rxjs";

import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {AngularFireAuth} from "@angular/fire/auth";
import firebase from "firebase";
import 'firebase/auth'

import {FirebaseDocumentChangeTracker} from "@common/changetracker";
import {IVoxletManifest, IVoxletSettings} from "@common/models";
import {Deferred} from "@common/deferred";

import {GlobalService} from "./global.service";
import {environment} from "@app/environments/environment";
import User = firebase.User;

export interface IVoiceSettings {
    id: string;
    speed: number;
}

export interface IVoiceSettingsSpecification {
    info: IVoiceSettings | null;
    content: IVoiceSettings | null
    dynamicContent: IVoiceSettings | null;
}

export enum VoxmateKeyboard {
    Disabled = 0,
    USQuertyKeyboard = 1
}

export enum TextToSpeechDriver {
    Streaming = 1,
    DirectAudio = 2
}

export interface IVoxmateSettings {

    enableHapticFeedbackOnMenuOptions: boolean;
    enableShushGesture: boolean;
    enableSpeechView: boolean;
    surfaceTheme: number;
    devicePower: number;
    useImperialUnits: boolean;
    enableTalkBackCompatibilityMode: boolean;
    enableTelegramIntegration: boolean;
    keyboard: VoxmateKeyboard

    preferredLanguage: null | "en-US" | "ru-RU" | "et-EE" | "es-ES";
    textToSpeechSettings: {
        textToSpeechDriver: TextToSpeechDriver;
        allowCloudVoices: boolean;
        defaultSpeed: number;
        "en-US": IVoiceSettingsSpecification;
        "ru-RU": IVoiceSettingsSpecification;
        "et-EE": IVoiceSettingsSpecification;
        "es-ES": IVoiceSettingsSpecification;
    }
}

export interface ISignInResult {
    signedIn: boolean
    signInError?: string
}

interface IPortalLinkResult {
    ok: boolean;
    data: {
        token: string
    }
    error: string;
}

interface IGoogleUser {
    nick: string;
    name: string;
    email: string;
}

function splitName(name: string): string {
    if (!name)
        return null;
    const nameParts = name.split(" ");
    if (nameParts.length > 1)
        return nameParts[0];
    return name
}

function createNickName(email: string, name: string) {

    const nameSplit = splitName(name);
    if (nameSplit)
        return nameSplit;

    if (email) {
        const emailParts = email.split("@");
        if (emailParts.length == 2) {
            const asName = emailParts[0].replace(/\./g, ' ');
            return splitName(asName);
        }
    }

    return "User";
}

const SIGN_IN_WITH_GOOGLE_MARK = "sign_in_with_google";

export enum AccountLinkErrorKind {
    Unknown,
    CancelledByUser,
    AccountInUse
}

interface IAccountLinkResult {
    ok: boolean;
    error?: AccountLinkErrorKind;
    exception?: any;
}


@Injectable({
    providedIn: 'root'
})
export class SessionService implements CanActivate {

    private settingsChangeTracker: FirebaseDocumentChangeTracker<IVoxmateSettings>;
    private user = new Deferred<User>();
    private jwt: string = null;

    private _voxlets: Observable<IVoxletManifest[]>;
    private _voxletsObservable = new Deferred<Observable<IVoxletManifest[]>>();
    private _userSignedIn = false;
    private _googleUser: IGoogleUser = null;
    private _userFuture: boolean = false;
    private _userSigningIn: boolean = false;
    private _signOutRequested: boolean = false;

    private async route(path: string, user: User = null): Promise<string> {
        user = user || await this.user.promise();
        return `users/${user.uid}/public/${path}`;
    }

    private async updateGoogleUserAccount() {
        this._googleUser = null;

        const google = new firebase.auth.GoogleAuthProvider();
        const user = await this.auth.currentUser;
        for (let providerData of user.providerData) {
            if (google.providerId === providerData.providerId) {
                this._googleUser = {
                    nick: createNickName(providerData.email, providerData.displayName),
                    name: providerData.displayName,
                    email: providerData.email
                }
            }
        }
    }

    constructor(
        private readonly global: GlobalService,
        private readonly client: HttpClient,
        private readonly router: Router,
        private readonly http: HttpClient,
        private readonly auth: AngularFireAuth,
        private readonly db: AngularFirestore,
        private readonly zone: NgZone) {

        this._userSigningIn = localStorage.getItem(SIGN_IN_WITH_GOOGLE_MARK) === "true";

        if (this._userSigningIn)
            setTimeout(async () => {
                await global.announce("Signing in", true);
            })

        auth.authState.subscribe(async (user) => {

            if (user != null) {
                console.log("IAM", user.uid)

                global.enableLoadingState();
                this._userFuture = true;

                const settings = db.doc<IVoxmateSettings>(await this.route("voxmate", user));
                this.settingsChangeTracker = new FirebaseDocumentChangeTracker(settings, zone);

                const repo = environment.voxletRepository;
                this._voxlets = db.collection<IVoxletManifest>(repo).valueChanges();

                if (await this.settingsChangeTracker.ready) {
                    this._userSignedIn = true;
                    await this.updateGoogleUserAccount();
                    this.user.resolve(user);
                    this.jwt = await user.getIdToken();

                    this._voxletsObservable.resolve(this._voxlets);
                    global.disableLoadingState();
                    if (this.router.url === "/login")
                        await this.router.navigate(['/']);
                } else {
                    global.disableLoadingState();
                    await this.signOut(false)
                    await this.router.navigate(['/invalid-account']);
                }
            } else {

                if (this._userSignedIn && !this._signOutRequested) {
                    if (this.settingsChangeTracker)
                        this.settingsChangeTracker.close();
                    location.reload();
                }

                this._userSignedIn = false;
            }

            if (this._userSigningIn) {
                localStorage.removeItem(SIGN_IN_WITH_GOOGLE_MARK);
                this._userSigningIn = false;
            }
        });
    }

    get isSigningIn(): boolean {
        return this._userSigningIn;
    }

    get isSignedIn() {
        return this._userSignedIn
    }

    get googleAccount() {
        return this._googleUser;
    }

    async getVoxletConfigurationReferenceFor(ident: string): Promise<AngularFirestoreDocument<IVoxletSettings>> {
        const path = await this.route(`settings/voxlets/${ident}`);
        return this.db.doc<IVoxletSettings>(path);
    }

    async getSettings() {
        await this.user.promise();
        if (await this.settingsChangeTracker.ready)
            return this.settingsChangeTracker.data;
        return null;
    }

    async voxlets(): Promise<Observable<IVoxletManifest[]>> {
        return await this._voxletsObservable.promise()
    }

    trigger() {
        this.zone.runOutsideAngular(() => {
            setTimeout(async () => {
                if (this.settingsChangeTracker)
                    await this.settingsChangeTracker.triggerDebounced();
            });
        });
    }

    async getIdToken() {
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
            // Send token to your backend via HTTPS
            // ...
        })
    }

    async signInWithToken(token: string): Promise<ISignInResult> {

        const url = environment.host
        let result: IPortalLinkResult = null;
        try {
            result = await this.http.post(url, {
                "action": "consumePortalToken",
                "token": token.toLowerCase()
            }).toPromise() as any;
        } catch (e) {
            console.warn(e)
        }

        if (!result)
            return {
                signedIn: false,
                signInError: "An unknown error has occurred"
            };

        if (!result.ok) {
            return {
                signedIn: false,
                signInError: result.error
            }
        }

        await this.auth.signInWithCustomToken(result.data.token);
        await this.router.navigate(['/']);

        return {
            signedIn: true
        }
    }

    async signInWithGoogle() {
        localStorage.setItem(SIGN_IN_WITH_GOOGLE_MARK, "true");
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
            'prompt': 'select_account'
        });
        await this.auth.signInWithRedirect(provider);
    }

    async signOut(redirectToLogin: boolean = true) {

        this._signOutRequested = true;

        if (this.settingsChangeTracker) {
            await this.global.freeze(this.settingsChangeTracker.flush(), "Saving changes");
            await this.global.freeze(this.settingsChangeTracker.operationsEnd(), "Saving changes, please wait");
            this.settingsChangeTracker.close();
        }

        this.settingsChangeTracker = null;

        this.user = new Deferred<User>();
        this.jwt = null;

        localStorage.removeItem(SIGN_IN_WITH_GOOGLE_MARK);

        await this.auth.signOut();
        if (redirectToLogin)
            await this.router.navigate(['/login']);

        this._signOutRequested = false;
    }

    async canActivate(): Promise<boolean> {
        await this.user.promise();
        return this._userSignedIn;
    }

    async fhttp(func: string, action: string, payload: any = {}): Promise<any> {

        const fhttpUrl = `https://apis.voxmate.com/v1/${func}`
        console.log("Posting");
        const data = await this.client.post(fhttpUrl, {
            "action": action,
            ...payload
        }, {
            headers: {"voxmate-web-auth-token": this.jwt}
        }).toPromise() as { ok: boolean, error: string, data: any };

        console.log("DATA", data);


        if (!data) {
            throw new Error("Unable to perform FHTTP request");
        }

        if (!data.ok) {
            if (data.error) {
                throw new Error(`Data error: ${data.error}`);
            } else {
                throw new Error("Unspecified FHTTP Error");
            }
        }

        return data.data;
    }
}
