<div class="document-view" *ngIf="settings">
    <h1 smartfocus>General Settings</h1>

    <div class="field" *ngIf="false">
        <h2 class="name">Language</h2>
        <div class="control">
            <mat-form-field>
                <mat-select [(ngModel)]="settings.preferredLanguage" placeholder="Language">
                    <mat-option *ngFor="let language of languages" [value]="language.code">
                        {{language.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <p class="explainer">
            Select the language to use for localized voxlets.
        </p>
    </div>

    <div class="field">
        <h2 class="name">Voice Speed
            <span class="highlight">
            {{settings.textToSpeechSettings.defaultSpeed}}x
            </span>
        </h2>
        <div class="control slider">
            <button mat-icon-button color="accent" (click)="nudgeDefaultSpeed(-0.25)" aria-label="Lower Speed"
                    [disabled]="voiceSpeedAtMinimum">
                -
            </button>
            <mat-slider min="1" max="4" step="0.5" tickInterval="0.5" thumbLabel="true"
                        [(ngModel)]="settings.textToSpeechSettings.defaultSpeed" aria-hidden="true"></mat-slider>

            <button mat-icon-button color="accent" (click)="nudgeDefaultSpeed(0.25)" aria-label="Increase Speed"
                    [disabled]="voiceSpeedAtMaximum">
                +
            </button>

        </div>
        <p class="explainer">
            Set the speed of Text-to-Speech voices in Voxmate. To pick your favorite voices, go to
            <voxmate-path path="Voxmate->Settings->Text to Speech"></voxmate-path>
            on your device.
        </p>
        <div class="good note" *ngIf="haveSetupCustomVoices">
            <p>
                <mat-icon class="great">check</mat-icon>
                You have customized some voice settings.
            </p>
            <div class="suble-actions">
                <button mat-raised-button color="accent" (click)="resetCustomVoiceSettings()">
                    <mat-icon>delete</mat-icon>
                    Reset Voice Settings
                </button>
            </div>

        </div>

        <div class="warning note" *ngIf="!haveSetupCustomVoices">
            <p>
                You should go into settings on your device, and pick the voices you like best.
            </p>
        </div>
    </div>

    <div class="field">
        <h2 class="name">Device Power</h2>
        <div class="control">
            <select [(ngModel)]="settings.devicePower">
                <option [ngValue]="0.25">Very Old</option>
                <option [ngValue]="0.5">Older or Modest</option>
                <option [ngValue]="1">Mid-range</option>
                <option [ngValue]="2">Flagship</option>
            </select>
        </div>
        <p class="explainer">
            Modern devices create more natural speech, while less powerful optimize to make speech faster.
        </p>
    </div>

    <div class="field">
        <h2 class="name">Measurement Units</h2>
        <div class="control">
            <select [(ngModel)]="settings.useImperialUnits">
                <option [ngValue]="true">Imperial</option>
                <option [ngValue]="false">Metric</option>
            </select>
        </div>
        <p class="explainer">
            Choose which measurement units you are most comfortable with.
        </p>
    </div>

    <div class="field">
        <h2 class="name">Touch Keyboard</h2>
        <div class="control">
            <select [(ngModel)]="settings.keyboard">
                <option [ngValue]="undefined" [disabled]="settings.keyboard !== undefined">Unspecified</option>
                <option [ngValue]="0">Disabled</option>
                <option [ngValue]="1">US QWERTY Keyboard Simplified</option>
                <option [ngValue]="2">US QWERTY Keyboard with Gestures</option>
            </select>
        </div>
        <p class="explainer">
            Editing text in Voxmate can be optionally done with an on-screen keyboard. Be aware, that the keyboard
            re-orients your screen to landscape mode when visible.
        </p>
    </div>

    <div class="field">

        <h2 class="name">Network Voices</h2>
        <div class="control">
            <mat-checkbox [(ngModel)]="settings.textToSpeechSettings.allowCloudVoices" name="enableSpeechView">
                Allow Text to Speech Engine to use the Internet
            </mat-checkbox>
        </div>

        <p class="explainer">
            If disabled, only voices on your device will be used.
            This may be useful if the voices are slow to respond or stutter.
            Please note that some third party voices might not work well with Voxmate.
        </p>
    </div>

    <div class="field">

        <h2 class="name">TalkBack Compatibility Mode</h2>
        <div class="control">
            <mat-checkbox [(ngModel)]="settings.enableTalkBackCompatibilityMode" name="enableSpeechView">
                Allow TalkBack and Voxmate to coexist peacefully
            </mat-checkbox>
        </div>

        <p class="explainer">
            If enabled Voxmate will not prompt you to disable TalkBack. You can use Voxmate alongside TalkBack
            by swiping with two fingers. Some gestures, like recording are not available in this mode.
            The Double-Tap Gesture is replaced with an L-gesture. For more information see the help section in Voxmate.
        </p>
    </div>

    <div class="field">
        <h2 class="name">Show Spoken Text on Screen</h2>
        <div class="control">
            <mat-checkbox [(ngModel)]="settings.enableSpeechView" name="enableSpeechView">
                Enable Showing Spoken Text on Screen
            </mat-checkbox>
        </div>
        <p class="explainer">
            When enabled, voxmate will show spoken text on screen in large print.
            Disabling this setting can prolong your battery life on newer phones with OLED screens.
        </p>
    </div>

    <div class="field">
        <h2 class="name">Tap and Hold to Pause Gesture</h2>
        <div class="control">
            <mat-checkbox [(ngModel)]="settings.enableShushGesture" name="enableSpeechView">
                Enable Tap and Hold to Pause
            </mat-checkbox>
        </div>
        <p class="explainer">
            Tap and hold your finger on the screen to pause Voxmate at any time. Touch again to continue.
            This may be useful for situations when you need to interact with people, but don’t want to lose your
            position in the app.
        </p>
    </div>

    <div class="field">
        <h2 class="name">Haptic Feedback</h2>
        <div class="control">
            <mat-checkbox [(ngModel)]="settings.enableHapticFeedbackOnMenuOptions" name="enableSpeechView">
                Enable Haptic Feedback
            </mat-checkbox>
        </div>
        <p class="explainer">
            Haptic feedback provides tactile notifications when you navigate through menus.
            This feature may be useful to differentiate between menus and content.
        </p>
    </div>

    <div class="field">
        <h2 class="name">Visual Theme</h2>
        <div class="control">
            <select [(ngModel)]="settings.surfaceTheme">
                <option [ngValue]="0">Default Theme</option>
                <option [ngValue]="1">High Contrast Theme</option>
                <option [ngValue]="2">White on Blue</option>
            </select>
        </div>
        <p class="explainer">
            This option allows you to customize the color scheme of text and background.
        </p>
    </div>


    <div class="field">
        <h2 class="name">Text to Speech Audio Driver</h2>
        <div class="control">
            <select [(ngModel)]="settings.textToSpeechSettings.textToSpeechDriver">
                <option [ngValue]="1">Streaming</option>
                <option [ngValue]="2">Direct Audio (BETA)</option>
            </select>
        </div>
        
        <p class="explainer">
            Direct Audio is a beta driver that should give you the best TTS experience.
            If you have problems with your TTS, you should try the streaming driver instead.
        </p>
    </div>

</div>

