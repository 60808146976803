export interface ILanguageDescriptor {
    code: string|null;
    name: string;
}

export const SUPPORTED_LANGUAGES: ILanguageDescriptor[] = [
    {code: null, name: "Automatic (English)"},
    {code: "en-US", name: "English"},
    {code: "ru-RU", name: "Russian"},
    {code: "es-ES", name: "Spanish"},
    {code: "et-EE", name: "Estonian"},
];
