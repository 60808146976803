<div class="browser-warning" *ngIf="global.browserUnsupported">
    <div class="icon"></div>
    <p>
        <strong>Internet Explorer</strong> is not supported for the portal.
        You may run into issues. Please upgrade to a more modern browser, such as
        <a href="https://www.microsoft.com/en-us/edge">EDGE</a>,
        <a href="https://support.apple.com/downloads/safari">Safari</a>,
        <a href="https://www.google.com/chrome">Chrome</a>, or
        <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>.
        If you rely on Internet Explorer for accessibility, and can't switch - please email us at
        <a href="mailto:support@voxmate.com">support@voxmate.com</a> and explain your situation.
    </p>
</div>

<div class="frost" *ngIf="global.isFrosted" cdkTrapFocus role="dialog" [class.compact]="global.compact">
    <div class="content">
        <mat-spinner></mat-spinner>
        <div class="text" smartfocus tabindex="0">
            {{global.frostedMessage}}
        </div>
    </div>
</div>

<nav [class.compact]="global.compact">
    <div class="nav-content">
        <div class="logo" (click)="goHome()" role="button" aria-label="My Voxmate">
            <span class="dev" *ngIf="global.isDev" (click)="global.toggleDevOverride()" title="This is the DEV version of the PORTAL.">
                <i class="fas fa-exclamation-circle"></i>
                DEV
            </span><span>my</span> voxmate
        </div>
        <div class="span"></div>
        <div class="back-button">
            <button aria-label="Back to home" mat-icon-button (click)="goHome()" *ngIf="hasBackButton" color="primary">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div class="back-button">
            <button aria-label="Sign out" mat-icon-button (click)="signOut()" *ngIf="signedIn && !hasBackButton"
                    color="primary">
                <fa-icon icon="sign-out-alt"></fa-icon>
            </button>
        </div>
    </div>
    <div class="nav-loading">
        <mat-progress-bar
                *ngIf="isLoading"
                mode="indeterminate" color="accent"></mat-progress-bar>
    </div>
</nav>

<div class="signing-in-section" *ngIf="session.isSigningIn">
    <div class="sign-in-card">
        <mat-spinner mode="indeterminate"></mat-spinner>
        <div class="card-text">
            Just a moment, signing in...
        </div>
    </div>
</div>

<div class="main-section" [class.compact]="global.compact">
    <router-outlet></router-outlet>
</div>
