import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from "@services/session.service";
import {Observable, Subscription} from "rxjs";
import {first, map} from "rxjs/operators";
import {GlobalService} from "@services/global.service";
import {IVoxletManifest} from "@common/models";
import {ActivatedRoute, Router} from "@angular/router";

const catIconMap = {
    "game": "fa-gamepad",
    "system": "settings",
    "utility": "fa-tools",
    "book": "fa-book",
    "main": "star",
    "communication": "fa-mobile",
    "news": "fa-newspaper",
    "podcast": "fa-podcast",
    "radio": "fa-broadcast-tower",
    "resto": "fa-utensils",
    "transport": "fa-bus",
    "help": "fa-life-ring",
    "fileplayer": "fa-sd-card",
    "reddit": "fab-reddit",
    "development": "fa-bug"
};

function sortByCategoryValue(array: IVoxletManifest[]) {

    const catPriorityMap = {
        "game": 200,
        "system": 2,
        "utility": 7,
        "book": 6,
        "main": 1,
        "communication": 3,
        "news": 4,
        "podcast": 5,
        "radio": 100,
        "resto": 100,
        "transport": 100,
        "help": 3,
        "fileplayer": 100
    };

    function categoryValue(voxlet: IVoxletManifest) {
        return catPriorityMap[voxlet.category] || 100;
    }

    function compareByCategoryValue(a: IVoxletManifest, b: IVoxletManifest) {

        const av = categoryValue(a);
        const bv = categoryValue(b);

        if (av < bv)
            return -1;

        if (av > bv)
            return 1;

        return 0;
    }

    array.sort(compareByCategoryValue);
    return array;
}

@Component({
    selector: 'voxmate-voxlets',
    templateUrl: './voxlets-page.component.html',
    styleUrls: ['./voxlets-page.component.less']
})
export class VoxletsPage implements OnDestroy, OnInit {

    loading = true;
    currentVoxlet: IVoxletManifest = null;
    private _voxlets: Observable<IVoxletManifest[]> | null = null;
    private _voxletSubscription: Subscription | null = null;
    voxlets: IVoxletManifest[];
    currentVoxletDescriptorModel: IVoxletManifest;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        public readonly session: SessionService,
        public readonly global: GlobalService) {
    }

    async ngOnInit() {
        this._voxlets = (await this.session.voxlets()).pipe(
            map(v => sortByCategoryValue(v))
        );

        this.global.enableLoadingState();

        this._voxletSubscription = this._voxlets.subscribe(async (voxlets) => {

            const voxletsWithSettings: IVoxletManifest[] = [];

            for (let voxlet of voxlets) {
                if (voxlet.settings) {
                    const fields = voxlet.settings.fields
                    if (fields && fields.length > 0) {
                        if (this.global.isProd && voxlet.development)
                            continue;
                        voxletsWithSettings.push(voxlet);
                    }
                }
            }

            this.voxlets = voxletsWithSettings;

            if (!this.currentVoxlet) {

                const params = this.route.snapshot.params;

                let ident = "main";
                if (this.voxlets.length > 0)
                    ident = this.voxlets[0].ident;

                if ("ident" in params)
                    ident = params["ident"];

                for (let voxlet of voxlets) {
                    if (voxlet.ident === ident) {
                        await this.open(voxlet);
                        break;
                    }
                }
            }

            this.route.params.subscribe(async (params) => {

                if (!("ident" in params))
                    return;

                const ident = params["ident"];
                for (let voxlet of voxlets) {
                    if (voxlet.ident === ident) {
                        await this.open(voxlet);
                        break;
                    }
                }
            })
        });

        this._voxlets.pipe(first()).subscribe(() => {
                this.global.disableLoadingState();
                this.loading = false;
            }
        );
    }

    voxletNeedsFontAwesomeIcon(voxlet: IVoxletManifest) {
        return this.getVoxletIcon(voxlet).startsWith("fa-") || this.voxletNeedsBrandIcon(voxlet);
    }

    voxletNeedsBrandIcon(voxlet: IVoxletManifest) {
        return this.getVoxletIcon(voxlet).startsWith("fab-");
    }

    getVoxletBrandIcon(voxlet: IVoxletManifest) {
        return this.getVoxletIcon(voxlet).replace("fab-", "fa-");
    }

    getVoxletIcon(voxlet: IVoxletManifest) {

        if (voxlet.icon) {
            const icon = catIconMap[voxlet.icon];
            if (icon)
                return icon;
        }

        return catIconMap[voxlet.category] || "device_unknown"
    }

    async open(voxlet: IVoxletManifest) {
        this.currentVoxletDescriptorModel = voxlet;
        const replace = this.currentVoxlet === null
        await this.router.navigate(["apps", voxlet.ident], {
            replaceUrl: replace,
        });
        this.currentVoxlet = voxlet;
    }

    ngOnDestroy(): void {
        this._voxletSubscription?.unsubscribe();
    }
}
