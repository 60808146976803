import {Component, OnInit} from '@angular/core';
import {IVoxmateSettings, SessionService} from "@services/session.service";
import {SUPPORTED_LANGUAGES} from "@app/constants";
import {GlobalService} from "@services/global.service";

@Component({
    selector: 'voxmate-services',
    templateUrl: './services-page.html',
    styleUrls: ['./services-page.less']
})
export class ServicesPage implements OnInit {

    settings: IVoxmateSettings;
    languages = SUPPORTED_LANGUAGES;

    constructor(
        private readonly session: SessionService,
        private readonly global: GlobalService
    ) {
    }

    async ngOnInit() {
        this.global.enableLoadingState();
        this.settings = await this.session.getSettings();
        this.global.disableLoadingState();
    }
}
