import {AfterViewInit, Directive, DoCheck, ElementRef, NgZone} from '@angular/core';

@Directive({
    selector: '[smartfocus]'
})
export class SmartFocusDirective implements AfterViewInit, DoCheck {

    private readonly element: HTMLElement;
    private _value: string;

    constructor(private readonly elem: ElementRef, private readonly zone: NgZone) {
        this.element = this.elem.nativeElement;
        this.element.tabIndex = -1;
        this._value = this.element.innerText
    }

    private focus() {
        const text = this.element.innerText;
        if (this._value != text) {
            setTimeout(() => this.element.focus());
            this._value = text
        }
    }

    ngDoCheck() {
        this.zone.runOutsideAngular(() => {
            this.focus()
        });
    }

    ngAfterViewInit(): void {
        this.focus()
    }
}
