import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {IVoxletConfiguration, IVoxletSettingsField} from "@common/models";

interface ICredentialsStore {
    username: string;
    password: string;
}

@Component({
    selector: 'voxmate-credentials',
    templateUrl: './credentials.component.html',
    styleUrls: ['./credentials.component.less']
})
export class CredentialsComponent implements OnInit {

    @Input() field: IVoxletSettingsField;
    @Input() config: IVoxletConfiguration;

    username: string = "";
    password: string = "";

    ngOnInit() {
        const settings = this.config[this.field.target];
        if (settings) {
            const store = JSON.parse(settings) as ICredentialsStore;
            this.username = store.username;
            this.password = store.password;
        }
    }

    save() {
        const store: ICredentialsStore = {
            username: this.username,
            password: this.password
        };

        this.config[this.field.target] = JSON.stringify(store);
    }

    clear() {
        this.config[this.field.target] = null;
        this.username = "";
        this.password = "";
    }
}
