import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

interface IDialogData {
    title: string;
    text: string;
    level: "warning"
}

@Component({
    selector: 'voxmate-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.less']
})
export class NotificationComponent {

    constructor(public dialogRef: MatDialogRef<NotificationComponent>,
                @Inject(MAT_DIALOG_DATA) public data: IDialogData) {
    }

    close() {
      this.dialogRef.close()
    }
}
