<div class="setup-status correct" *ngIf="isSetupCorrectly">
    <div class="span">
        <mat-icon>checkmark</mat-icon>
        <div class="text">Reddit has been setup correctly</div>
    </div>
</div>

<div class="setup-status invalid" *ngIf="isSetupInvalid">
    <div class="span">
        <mat-icon>error</mat-icon>
        <div class="text">There's a problem with your Reddit account. Try logging in again.</div>
    </div>
</div>

<div class="controls" *ngIf="isSetupInvalid || needsSetup">
    <button mat-raised-button color="primary" (click)="initRedditLogin()">
    <span>
        <mat-icon fontSet="fab" fontIcon="fa-reddit"></mat-icon>
            Login to Reddit
        </span>
    </button>
</div>

<div class="controls" *ngIf="isSetupCorrectly">
    <button mat-raised-button color="primary" (click)="disconnectReddit()">
    <span>
        <mat-icon fontSet="fab" fontIcon="fa-reddit"></mat-icon>
            Disconnect Reddit
        </span>
    </button>
</div>

