<div class="document-view" *ngIf="settings">
    <h1 smartfocus>Third Party Services</h1>

    <div class="field">

        <h2 class="name">Telegram Instant Messaging</h2>
        <div class="control">
            <mat-checkbox [(ngModel)]="settings.enableTelegramIntegration">
                Enable Telegram
            </mat-checkbox>
        </div>

        <p class="explainer">
            Telegram is a secure cloud based instant messaging service, that supports private chat, group chat and
            secret messages. Voxmate comes bundled with its own Telegram client. When you enable this integration,
            you will be able to setup Telegram from your phone. Note, this will also enable background telegram
            notifications.
        </p>

    </div>

</div>

