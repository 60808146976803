<mat-tab-group>

    <mat-tab label="My Stations">
        <ng-template mat-tab-label>
            <div class="label-container">
                My Stations
                <div class="bubble" *ngIf="stations.length === 0">empty</div>
                <div class="bubble" *ngIf="stations.length">{{stations.length}}</div>
            </div>
        </ng-template>

        <ul *ngIf="stations.length">
            <li *ngFor="let station of stations">
                <div class="station" *ngIf="station">
                    <div class="move-switcher">
                        <button [attr.aria-label]="'Promote' + station.name" (click)="move(station, -1)">
                            <fa-icon icon="chevron-up"></fa-icon>
                        </button>
                        <button [attr.aria-label]="'Demote' + station.name" (click)="move(station, 1)">
                            <fa-icon icon="chevron-down"></fa-icon>
                        </button>
                    </div>
                    <div class="card">
                        <div class="info">
                            <h4>{{station.name}}</h4>
                            <div class="description">{{station.description}}</div>
                            <div class="group" *ngIf="group">
                                <label>
                                    Group As:
                                    <input [(ngModel)]="station.group">
                                </label>
                            </div>
                        </div>
                        <button class="quick-action"
                                (click)="removeStation(station)" [attr.aria-label]="'Remove' + station.name">
                            <fa-icon icon="times"></fa-icon>
                        </button>
                    </div>
                </div>
            </li>
        </ul>

        <div class="no-data" *ngIf="!stations.length">
            <div class="icon">
                <fa-icon icon="truck-loading"></fa-icon>
            </div>
            You don't have any stations setup yet. Go find some stations.
        </div>
    </mat-tab>

    <mat-tab label="Find New Stations">
        <div class="find">

            <div class="search-field">
                <label>
                    Search
                    <input [(ngModel)]="searchString" (keyup.enter)="search()" smartfocus>
                </label>
                <button (click)="search()" mat-raised-button color="primary">search</button>
            </div>

            <ul *ngIf="searchResults.length" class="results">
                <li *ngFor="let station of searchResults">
                    <div class="station">
                        <div class="card">
                            <div class="info">
                                <h4>{{station.name}}</h4>
                                <div class="description">{{station.description}}</div>
                            </div>
                        </div>
                        <button class="quick-action" mat-raised-button color="primary"
                                *ngIf="isStationAddable(station)"
                                (click)="addStation(station)"
                                [attr.aria-label]="'Add' + station.name">
                            <fa-icon icon="plus"></fa-icon>
                        </button>
                        <div class="checkmark" *ngIf="!isStationAddable(station)">
                            <fa-icon icon="check"></fa-icon>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="no-data" *ngIf="searched && searchResults.length === 0">
                <div class="icon">
                    <fa-icon icon="seedling"></fa-icon>
                </div>
                Couldn't find anything, sorry.
            </div>
        </div>

    </mat-tab>

</mat-tab-group>




